import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import { gamesConstants } from "../constants";
import { loadedGames } from "../actions/games";

import { buildApiUrl } from "../../utils/api";
import { accessPages, operationsAccess } from "../../utils/users";

const gamesApiUrl = buildApiUrl("/admin/bonuses/selects/games");

function* loadGamesSaga(action) {
  const opAccess = operationsAccess(accessPages.BONUSES_SELECTS);

  try {
    if (!opAccess.canList) return;
    const { data } = yield axios.get(`${gamesApiUrl}`);

    yield put(loadedGames(data));
  } catch (e) {
    console.log("[SAGA] loadGamesSaga error", e.toString());
  }
}

export default function* watchGamesSaga() {
  //console.log("[SAGA] watching games");

  yield takeEvery(gamesConstants.LOAD, loadGamesSaga);
}
